<template>
  <div class=" servicesDecobg">
    <!-- <img src="./../../assets/Images/We_Offer1.png" alt=""> -->
    <div class=" weoffer-background">
      <div class="grid grid-flow-col lg:justify-items-end servicesDecobg2">
        <div class="grid pr-4 lg:justify-items-end">
          <div class="md:pl-10">
            <h1
              class="
                text-StemBlue
                font-bold
                text-4xl
                pr-4
                border-StemPurple border-r-2
                text-right
              "
            >
              STEM <br />
              edukimi
            </h1>
          </div>
        </div>
        <div class="dotbg bg-no-repeat p-20"></div>
      </div>
    </div>
  </div>
  <div class="lg:px-28">
    <div
      class="
        grid grid-flow-row
        place-items-center
        md:grid-cols-2
        xl:grid-cols-4
        circlebg
        bg-no-repeat
        
      "
    >
      <div
        class="h-96 w-80 grid place-items-center"
      >
        <div
          class="
            grid grid-row-3
            place-items-center
            h-80
            w-64
            rounded-3xl
            bg-white
            z-40
            transition
            duration-500
            ease-in-out
            transform
            hover:-translate-y-1 hover:scale-110 hover:shadow-xl
          "
        >
          <div class="pt-4">
            <img src="./../../assets/Svg/offersvg.svg" alt="" />
          </div>
          <div class="">
            <h1 class="text-StemBlue text-lg pt-2">Programet</h1>
          </div>
          <div class="">
            <p class="text-xs h-28 text-StemTextSecond px-12">
              Programet e STEMLab janë alternativë unike e edukimit, që stimulojnë mendimin kritik dhe inovativ, dashurinë dhe përkushtimin, punë praktike dhe shkathtësinë.
            </p>
          </div>
        </div>
      </div>
      <div
        class="
          
          h-96
          w-80
          cardbackground
          grid
          place-items-center
        "
      >
        <div
          class="
            grid grid-row-3
            place-items-center
            shadow-lg
            h-80
            w-64
            rounded-3xl
            bg-white
            transition
            duration-500
            ease-in-out
            transform
            hover:-translate-y-1 hover:scale-110 hover:shadow-xl
          "
        >
        
          <div class="pt-4">
            <img src="./../../assets/Svg/vizionisvg.svg" alt="" />
          </div>
          <div class="">
            <h1 class="text-StemBlue0 text-lg pt-2">Vizioni</h1>
          </div>
          <div class="">
            <p class="text-xs h-28 text-StemTextSecond px-12">
              Formimi i personaliteteve që kanë ide, pasion dhe zell për punën e tyre, dhe që arrijnë të ndërtojnë të ardhmen e tyre në harmoni me natyrën dhe mjedisin ku jetojnë.
            </p>
          </div>
        </div>
      </div>
      <div
        class=" h-96 w-80 grid place-items-center"
      >
        <div
          class="
            grid grid-row-3
            place-items-center
            h-80
            w-64
            rounded-3xl
            bg-white
            transition
            duration-500
            ease-in-out
            transform
            hover:-translate-y-1 hover:scale-110 hover:shadow-xl
          "
        >
          <div class="pt-4">
            <img src="./../../assets/Svg/misionisvg.svg" alt="" />
          </div>
          <div class="">
            <h1 class="text-StemBlue text-lg pt-2">Misioni</h1>
          </div>
          <div class="">
            <p class="text-xs h-28 text-StemTextSecond px-12">
              STEMLab është e fokusuar në edukimin e gjeneratave të reja, të cilat do të jenë të gatshme dhe të përkushtuara në përballjen me sfidat e së ardhmes.
            </p>
          </div>
        </div>
      </div>
      <div
        class="h-96 w-80 grid place-items-center"
      >
        <div
          class="
            grid grid-row-3
            place-items-center
            h-80
            w-64
            rounded-3xl
            bg-white
            transition
            duration-500
            ease-in-out
            transform
            hover:-translate-y-1 hover:scale-110 hover:shadow-xl
          "
        >
          <div class="pt-4">
            <img src="./../../assets/Svg/kreativitetisvg.svg" alt="" />
          </div>
          <div class="">
            <h1 class="text-StemBlue text-lg pt-2">Moto</h1>
          </div>
          <div class="">
            <p class="text-xs h-28 text-StemTextSecond px-12">
              Edukojmë mendjet, zemrat dhe mendjet e inovatorëve të së nesërmes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import WeOfferCards from './../../components/base/WeOfferCards.vue'
// export default {
//   components: { WeOfferCards },

// }
</script>

<style>
@media only screen and (min-width: 1025px) {
  .weoffer-background {
    background-image: url("./../../assets/images/We_Offer1.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
  }
  .cardbackground {
    background-image: url("./../../assets/Svg/cardundersvg.svg");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
  }
  .circlebg {
    background-image: url("./../../assets/Svg/circle1.svg");
    background-position-x: 20%;
    background-position-y: bottom;
    background-repeat: no-repeat;
    bottom: 0px;
    z-index: -1;
  }
  .dotbg {
    background-image: url("./../../assets/Svg/DotCluster.svg");
    background-position-x: 1%;
    background-position-y: bottom;
  }
  .servicesDecobg {
    background-image: url("./../../assets/Svg/servicesDeco.svg");
    background-repeat: no-repeat;
    background-position-x: 5%;
    background-position-y: top;
  }
  .servicesDecobg2 {
    background-image: url("./../../assets/Svg/servicesDeco2.svg");
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: bottom;
  }
}
@media only screen and (max-width: 1024px) {
 
  .servicesDecobg2 {
    background-image: url("./../../assets/Svg/servicesDeco2.svg");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 70%;
  }
  .weoffer-background {
    background-image: url("./../../assets/images/We_Offer1.png");
    background-repeat: no-repeat;
    background-position-x: top;
    background-position-y: center;
  }
  .cardbackground {
    background-image: url("./../../assets/Svg/cardundersvg.svg");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
  }
}
@media only screen and (max-width: 640px) {
}
</style>