<template>
  <div class="herobg">
    <div class="grid xl:grid-cols-2 herobg_deco">
      <div class="pt-52 lg:pt-48 px-2 md:px-10 lg:px-28">
        <h1 class="text-StemBlue font-black text-base xl:text-xl text-left md:w-3/4 xl:w-full">
          QENDRA PËR EDUKIM DHE MËSIM INOVATIV
        </h1>
        <h2 class="text-StemBlue font-bold text-5xl md:text-6xl text-left pt-6">
          STEMLab
        </h2>
        <!-- <p
          class="
            text-StemTextSecond
            font-bold
            leading-loose
            text-sm text-left
            pt-7
          "
        > -->
        <p class="text-StemRed font-normal text-xl text-left pt-7 md:w-80 xl:w-full">
          STEM edukimi ka potencialin e pajisjes së nxënësve me një sërë
          shkathtësishë për të njohur dhe mbijetuar në një mjedis kompleks dhe
          me sfida.
        </p>
        
        <div
          class="
            grid grid-rows-1
            py-10
            gap-3
            xl:grid-cols-2
            md:py-10
            lg:pt-24
            xl:py-48
            lg:text-left
          "
        >
          <div class="">
            <button
              @click="$router.push({ path: 'courses' })"
              class="
                rounded-lg
                bg-StemYellow
                hover:bg-transparent hover:text-StemYellow
                text-white
                border
                hover:border-StemYellow
                text-base
                font-normal
                w-48
                h-14
              "
            >
              Regjistrohu
            </button>
          </div>
          <div class="grid grid-rows-1 lg:grid-cols-2 place-items-center">
            <button
              @click="$router.push({ path: 'courses' })"
              class="
                w-14
                h-14
                rounded-full
                buttonbg
                bg-center bg-no-repeat bg-StemRed
                border
                shadow-2xl
                hover:bg-red-200 hover:border-red-300
              "
            >
              <!-- <img src="./../../assets/Svg/RedPlayButton.svg" alt=""> -->
            </button>
            <a href="/courses" class="StemTextSecond">Gjithë kurset</a>
          </div>
        </div>
      </div>
      <div class="">
        <!-- <img src="./../../assets/images/Background1.png" alt=""> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@media only screen and (min-width: 1025px) {
  .herobg {
    background-image: url("./../../assets/images/HeroeBgApple.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: 60%;
  }
  .buttonbg {
    background-image: url("./../../assets/Svg//ArrowRightHs2.svg");
    background-size: 50%;
    background-position-x: 60%;
    background-position-y: 50%;
  }
  .herobg_deco {
    background-image: url("./../../assets/Svg/heroedotdeco.svg");
    background-repeat: no-repeat;
    background-position-x: 3%;
    background-position-y: 35%;
  }
}
@media only screen and (max-width: 1024px) {
  .buttonbg {
    background-image: url("./../../assets/Svg//ArrowRightHs2.svg");
    background-size: 50%;
    background-position-x: 60%;
    background-position-y: 50%;
  }

  .herobg_deco {
    background-image: url("./../../assets/Svg/heroedotdeco.svg");
    background-repeat: no-repeat;
    background-position-x: 3%;
    background-position-y: 15%;
  }
  .herobg {
    background-image: url("./../../assets/images/HeroeBgApple.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: 60%;
  }
}
@media only screen and (max-width: 640px) {
  .buttonbg {
    background-image: url("./../../assets/Svg//ArrowRightHs2.svg");
    background-size: 50%;
    background-position-x: 60%;
    background-position-y: 50%;
  }
}
</style>