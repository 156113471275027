<template>
  <div class="md:px-24 pt-12 px-2 AboutCardsDeco1">
    <div class="border-StemPurple border-l-2 pl-4">
      <h1 class="text-StemBlue font-bold text-4xl pr-4 text-left">
        Partnerët
      </h1>
    </div>
  </div>

  <div class="py-10 md:px-24">
    <Carousel ref="flickity" :options="carouselOptions">
      <div class="carousel-cell justify-center" v-for="c in data1" :key="c">
        <img :src="c.image" alt="" />
      </div>
    </Carousel>
  </div>
</template>

<script>
import Carousel from "./../../components/base/carousel.vue";

import databot from "@/assets/images/Partners/databot.png";
import marif from "@/assets/images/Partners/Marrif3.png";
import StemPd from "@/assets/images/Partners/StemPd.png";

import stemorg1 from "@/assets/images/Partners/stemorg1.png";
import stemorg2 from "@/assets/images/Partners/stemorg2.png";
export default {
  components: {
    Carousel,
  },

  data() {
    return {
      data1: [
        {
          id: 0,
          image: marif,
        },
        {
          id: 1,
          image: databot,
        },
        {
          id: 2,
          image: StemPd,
        },
        {
          id: 4,
          image: stemorg1,
        },
        {
          id: 5,
          image: stemorg2,
        },
        {
          id: 6,
          image: marif,
        },
        {
          id: 7,
          image: databot,
        },
        {
          id: 8,
          image: StemPd,
        },
       
        {
          id: 10,
          image: stemorg1,
        },
        {
          id: 11,
          image: stemorg2,
        },
        {
          id: 12,
          image: marif,
        },
        {
          id: 13,
          image: databot,
        },
        {
          id: 14,
          image: StemPd,
        },
        
        {
          id: 17,
          image: stemorg1,
        },
        {
          id: 18,
          image: stemorg2,
        },
      ],
      carouselOptions: {
        initialIndex: 2,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: 1000,
        // cellAlign: 'right',
        // contain: true

        // any options from Flickity can be used
      },
    };
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1025px) {
  .AboutCardsDeco1 {
    background-image: url("./../../assets/Svg/AboutCardsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: bottom;
  }
}
@media only screen and (max-width: 1024px) {
  .AboutCardsDeco1 {
    background-image: url("./../../assets/Svg/AboutCardsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: bottom;
  }
}
@media only screen and (max-width: 640px) {
  .AboutCardsDeco1 {
    background-image: url("./../../assets/Svg/AboutCardsDeco1.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 80%;
  }
}
.carousel-cell {
  /* display: flex; */
  padding-top: 50px;
  justify-content: center;
  display: block;
  margin-right: 80px;
  width: 180px;
  height: 180px;
}
.carousel-cell.is-selected {
  background: transparent;
}
</style>