<template>
  <div class="">
    <li class="" @click="toggleShow">
      <div class="flex flex-row justify-end cursor-pointer">
        <div>{{ title }}</div>
        <div class="p-1">
          <svg
            width="15"
            height="15"
            :class="{ 'rotate-90': showMenu }"
            class="dropdown-menu"
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
          </svg>
        </div>
      </div>
      <!-- <ul class="overflow-hidden dropdown-menu mb-2" :class="this.class">
        <slot class=""></slot>
      </ul> -->
      <transition name="dropdown">
      <div class="dropdown__menu top-0 lg:-ml-8" v-bind:class="{ active: showMenu }" v-if="showMenu">
        <ul class="dropdown__menu-link mb-2 bg-gray-100 opacity-90" :class="this.class">
          <slot class=""></slot>
          
          <!-- Dropdown Menu Separator -->

        </ul>
      </div>
      </transition>
    </li>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      showMenu: false,
    };
  },

  methods: {
    toggleShow() {
      this.showMenu = !this.showMenu;
    },
  },

  computed: {
    class() {
      return this.showMenu ? this.height : "h-0";
    },
  },
};
</script>

<style>

svg.rotate-90 {
  transform: rotate(90deg);
}

@media only screen and (min-width: 1025px) {
.dropdown__menu {

  position: absolute;
  z-index: 10;
  height: max-content;
  min-width: 150px;
  margin-top: 0.5rem;
  overflow-y: auto;
  padding: 2rem 1rem 2rem 0rem;

}
.dropdown__menu-link {
  
  padding: 0.4rem 0 0.4rem 0rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  border-radius: 10px 10px 10px 10px;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
}
@media only screen and (max-width: 1024px) {
/* .dropdown__menu {

  position: relative;
  z-index: 10;
  height: max-content;
  min-width: 150px;
  margin-top: 0.5rem;
  overflow-y: auto;
  padding: 2rem 1rem 2rem 0rem;

} */
/* .dropdown__menu-link {
  
  padding: 0.4rem 0 0.4rem 0rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  border-radius: 10px 10px 10px 10px;
} */

/* .dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
} */
}
@media only screen and (max-width: 640px) {
  .dropdown__menu {

  position: relative;
  z-index: 10;
  height: max-content;
  min-width: 150px;
  /* margin-top: 0.5rem; */
  overflow-y: auto;
  /* padding: 2rem 1rem 2rem 0rem; */

}
/* .dropdown__menu-link {
  
  padding: 0.4rem 0 0.4rem 0rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  border-radius: 10px 10px 10px 10px;
} */

/* .dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
} */
}
</style>