<template>
  <div class="md:px-28 px-4 bg-gray-100">
    <div class="grid grid-flow-row md:grid-cols-3  pt-10">
      <!-- acreddited and menu -->
      <div class="">
      <div class="grid grid-cols-2 place-items-center">
        
        <div class="grid grid-flow-row pt-6 text-center">
          <router-link
            v-for="item in list"
            :key="item.label"
            :to="item.link"
            active-class="active"
          >
            <div
              class="
                font-normal
                text-lg
                
                StemTextSecond
                hover:text-StemLightRed
              "
            >
              {{ item.label }}
            </div>
          </router-link>
        </div>
        <div class="flex justify-center pt-10">
          <!-- <img class="w-48" src="./../../assets/images/sdg_-_wheel_-_smaller_2.png" alt=""> -->
          <img
            class="w-40 h-36"
            src="./../../assets/images/StemOrg.png"
            alt=""
          />
        </div>
      </div>
      </div>
      <!-- ---- -->
      <!-- accordian and contact -->
      <div class="md:col-start-2 md:col-span-2 row-span-2 pt-10">
        <div class="flex flex-col md:flex-row md:justify-between">
          <!-- Accordion -->
          <div class="px-5 md:ml-20 w-full md:w-3/4 gap-5 md:gap-0 pb-6 md:pb-0">
            <div class="grid grid-flow-row">
              <h1
                class=" pb-4
                 text-center
                  md:text-left text-lg
                  text-StemBlue
                  font-semibold
                "
              >
                FAQ
              </h1>
              <details
                class="flex justify-center text-center md:text-left"
                v-for="(item, index) in items"
                :key="`accordion_${index}`"
                :ref="`accordion_${index}`"
                @click.prevent="handleClick(index, 'accordion_')"
              >
                <summary class="cursor-pointer text-StemBlue">
                  {{ item.title }}
                </summary>
                <p class="text-StemTextSecond pl-4">{{ item.text }}</p>
              </details>
            </div>
          </div>
          <!--end Accordion -->
          <!-- media -->
          
          <!-- end media -->
          <!-- contacts -->
          <div class="">
            <h1
              @click="$router.push({ path: '/contactus' })"
              class="
                pb-4
                text-lg
                font-semibold 
                text-StemBlue
                cursor-pointer
                text-center
                md:text-left
              "
            >
              Kontakti
            </h1>
            <details
                class="flex justify-center text-center md:text-left md:w-40"
                v-for="(item, index) in items2"
                :key="`accordion_2${index}`"
                :ref="`accordion_2${index}`"
                @click.prevent="handleClick(index, 'accordion_2')"
              >
                <summary class="cursor-pointer text-StemBlue">
                  {{ item.title }}
                </summary>
                <p class="text-StemTextSecond pl-4">{{ item.text }}</p>
              </details>
          </div>
          <!-- end contacts -->
        </div>
      </div>
      <!-- accordian and contact -->
    </div>
    <div class="flex justify-center py-10">
      <div class="flex justify-between place-content-center gap-10">
        <div class="w-8">
          <a href="https://www.facebook.com/STEMLabTE/"><img src="./../../assets/Svg/FacebookStem.svg" alt="" /></a>
          
        </div>
        <div class="w-8">
          <a href="https://www.instagram.com/stemlab_te/?hl=en"><img src="./../../assets/Svg/InstagramStem.svg" alt="" /></a>
          
        </div>
        <div class="w-8">
          <a href="https://mk.linkedin.com/company/stemlab-te"><img src="./../../assets/Svg/LinkedInStem.svg" alt="" /></a>
          
        </div>
      </div>
    </div>
    <div class=" border-t-2 border-gray-400 py-3">
      <div class="flex justify-between px-10">
        <p>Copyrights {{year}}</p>
      <p>Developed by <a class="text-black font-semibold" target="_blank" href="https://web-pillar.com">WebPillar</a></p>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      items: [
        {
          title: "Si të regjistrohem?",
          text: "Regjistrimi bëhet në zyrat e STEMLab dhe online nga web-faqja!",
        },
        {
          title: "A mund të ndjek më shumë kurse njëkohësisht?",
          text: "Po mundet, stafi i STEMLab do të kujdeset për harmonizimin e orareve.",
        },
        {
          title: "Kur organizohen demo-orët?",
          text: "Me hapjen e aplikimeve për regjistrim, çdoherë organizohen demo-orë!",
        },
      ],
      items2:[
        {
            title: "E-mail",
            text: "info@stemlab.org.mk"
        },
        {
            title: "Kontakt telefon",
            text: "070 840 640"
        },
        {
            title: "Adresa",
            text: "Ilindenska 177, Tetovë 1220"
        },
      ],
      list: [
        { label: "Ballina", link: "/"},
        { label: "Për ne", link: "/about"},
        { label: "Kurset", link: "/courses"},
        { label: "IdeaLab", link: "/idealab"},
        // { label: "Produktet", link: "/products", sub: false, },
        { label: "Risitë", link: "/news"},
      ],
    };
  },
  methods: {
    handleClick(index, prefix) {
      for (const item in this.$refs) {
        if (item === `${prefix}${index}`) {
          this.$refs[`${prefix}${index}`].open =
            !this.$refs[`${prefix}${index}`].open;
        } else {
          this.$refs[item].open = false;
        }
      }
    },
  },
  computed:{
    year(){
      return new Date().getFullYear()
    }
  }
};
</script>

<style scoped>
.active {
  border-bottom: 1px solid;
  border-bottom-color: #f1a501;
}
</style>