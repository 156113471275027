const state = {
    cartItems: [],
    popupVisible: false,
}

const mutations = {
    ADD_PRODUCT_IN_CART(state, payload) {
        const product = { ...payload, amount: 0 };
        const itemIndex = state.cartItems.findIndex(i => i.id === payload.id);
        if (itemIndex === -1) {
            product.amount++;
            product.cartId = state.cartItems.length;
            state.cartItems.push(product);
            state.popupVisible=true;
        } else {
            state.cartItems[itemIndex].amount++;
        }
        
    },

    REMOVE_FROM_CART(state, id) {
        state.cartItems = state.cartItems.filter(i => i.cartId !== id);
    },

    REDUCE_AMOUNT(state, id) {
        const index = state.cartItems.findIndex(i => i.cartId === id);
        if (state.cartItems[index].amount >= 1) {
            state.cartItems[index].amount--;
        } else {
            state.cartItems = state.cartItems.filter(i => i.cartId !== id);
        }
    },
    CLOSE_POPUP(state){
        state.popupVisible=false;
    }
}

const actions = {
    addProductToCart({ commit }, payload) {
        commit('ADD_PRODUCT_IN_CART', payload)
        
    },
    removeProductFromCart({ commit }, id) {
        commit('REMOVE_FROM_CART', id)
    },
    reduceAmountOfProducts({ commit }, id) {
        commit('REDUCE_AMOUNT', id);
    },
    closePopup({commit}){
        commit('CLOSE_POPUP');
    }
}

const getters = {
    cartItems: state => state.cartItems,
    popupVisible: state => state.popupVisible,
}

const cartModule = {
    state,
    mutations,
    actions,
    getters
}

export default cartModule;
