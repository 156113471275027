<template>
  <div
    :class="isOpen ? 'relative' : 'absolute'"
    class="
      w-full
      bg-transparent
      flex
      lg:justify-between
      flex-col
      lg:flex-row
      sm:px-4 sm:py-3
      md:px-4 md:py-3
      lg:px-28
      bg-gray-100
      bg-opacity-90
      
    "
  >
    <div class="relative flex w-full justify-between md:pt-5">
      <router-link to="/">
        <div class="h-12 w-52 pt-2">
          <img src="./../../assets/Svg/StemLogo.svg" alt="" />
        </div>
      </router-link>
      <div class="lg:hidden p-2">
        <button
          @click="isOpen = !isOpen"
          type="button"
          class="block StemTextSecond focus:StemTextSecond focus:outline-none"
        >
          <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path
              v-if="isOpen"
              fill-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
            <path
              v-if="!isOpen"
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
      </div>
    </div>
    <nav
      :class="isOpen ? 'block' : 'hidden'"
      class="px-2 pt-2 pb-4 lg:flex sm:p-0"
    >
      <div class="mt-11 relative">
        <div class="flex justify-center">
          <ul
            class="
              flex flex-col
              lg:flex-row
              gap-6
              md:pt-1
              lg:gap-8
              xl:gap-14
              lg:mr-10
              w-max
              text-StemBlue
               font-StemNavFont
            "
          >
            <div v-for="item in items" :key="item.label">
              <router-link v-if="!item.sub" :to="item.link" active-class="active">
                {{item.label}}
              </router-link>
              <dropdown class="" v-else :title="item.label" >
                <router-link class="flex flex-row ml-2 hover:bg-gray-200" v-for="x in categories" :key="x" :to="`${item.link}/${x.id}`">{{ x.name }}</router-link>
              </dropdown>
            </div>
          </ul>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="overflow-visible flex flex-row gap-5 pt-7 cursor-pointer">
          <div :data-value="value" :data-list="list" class="w-10 mt-2">
            <div @click="toggle()">
              <div>
                <img class="w-10 h-5 mt-3" :src="value" alt="" />
              </div>
              <div :class="{ expanded: visible }"></div>
              <div :class="{ hidden: !visible, visible }">
                <ul class="w-max" :class="{ visible }">
                  <li
                    class=""
                    :class="{ current: item.image === value }"
                    v-for="item in list1"
                    :key="item"
                    :data="item"
                    @click="select(item)"
                  >
                    <img
                      v-if="value != item.image"
                      class="w-10 h-5 my-2"
                      :src="item.image"
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="">
            <router-link to="/checkout">
              <div class="relative w-5 h-5 mt-5">
                <img
                  src="./../../assets/Svg/shopping-cart.svg"
                  alt=""
                  class="object-cover w-full h-full"
                />
                <div
                  class="
                    absolute
                    w-4
                    h-4
                    rounded-full
                    -top-2.5
                    -right-2.5
                    bg-StemRed
                    text-white text-sm text-center
                    leading-tight
                  "
                >
                  {{ totalitems }}
                </div>
              </div>
            </router-link>
          </div> -->
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import al from "./../../assets/Svg/al.svg";
import mk from "./../../assets/Svg/mk.svg";
import gb from "./../../assets/Svg/gb.svg";
 import dropdown from "@/components/base/dropdown";
import categories from "./../../data/categories.js";

export default {
 components: { dropdown },
  data() {
    return {
      showInput: false,
      show: false,
      categories: categories.data,
      items: [
        { label: "Ballina", link: "/", sub: false, },
        { label: "Për ne", link: "/about", sub: false, },
        { label: "Kurset", link: "/courses", sub: true, },
        { label: "IdeaLab", link: "/idealab", sub: false, },
        // { label: "Produktet", link: "/products", sub: false, },
        { label: "Risitë", link: "/news", sub: false, },
      ],
      isOpen: false,
      value: al,
      list1: [
        {
          name: "al",
          image: al,
        },
        {
          name: "mk",
          image: mk,
        },
        {
          name: "gb",
          image: gb,
        },
      ],
      visible: false,
    };
  },
  computed: {
    ...mapGetters(["cartItems"]),
    totalitems() {
      return this.cartItems.length;
    },
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    select(option) {
      this.value = option.image;
    },
  },
};
</script>
<style scoped>
.active {
  border-bottom: 1px solid;
  border-bottom-color: #f1a501;
}
</style>
