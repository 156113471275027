import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About Us',
    component: () => import('../views/About.vue')
  },
  {
    path: '/courses/:category?',
    name: 'Our Courses',
    component: () => import('../views/Courses.vue')
  },
  {
    path: '/products',
    name: 'Our Products',
    component: () => import('../views/Products.vue')
  },
  {
    path: '/courses_description/:id',
    name: 'Courses Description',
    component: () => import('../views/CourseDescription.vue')
  },
  {
    path: '/product_description/:id',
    name: 'Products Description',
    component: () => import('../views/ProductsDescription.vue')
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/checkout',
    name: 'CheckOut',
    component: () => import('../views/CheckOut.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/register',
    name: 'Registration',
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/idealab',
    name: 'Idealab',
    component: () => import('../views/Idealab.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/404.vue')

  },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'StemLab – EDUKOJMË MENDJET, ZEMRAT DHE DUART!';

router.beforeEach(() => {
  document.title = DEFAULT_TITLE;
  window.scrollTo(0, 0)
  // ...
})

export default router
