const data = [
    { id: 0, name: 'KURSET' },
    { id: 1, name: 'STEM' },
    { id: 2, name: 'Robotikë' },
    { id: 3, name: 'Programim' },
    { id: 4, name: 'Brainobrain' },
    { id: 5, name: 'Kurse shkollore' },
    { id: 6, name: 'Hands2Mind' },
    { id: 7, name: 'P-B Learning' },
];

export default { data }