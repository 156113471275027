import WeeDoo1 from "@/assets/images/cardimg1.png";
import printing from "@/assets/images/courses/3Dprintind.jpg";
import arduino from "@/assets/images/courses/Arduino.jpg";
import Brainobrain from "@/assets/images/courses/Brainobrain.jpg";
import CodeOrg from "@/assets/images/courses/CodeOrg.png";
import DaVinci from "@/assets/images/courses/DaVinci.jpg";
import Hands2Mind from "@/assets/images/courses/Hands2Mind.jpg";
import KurseShkollore from "@/assets/images/courses/KurseShkollore.jpg";
import LITTLEBOBS from "@/assets/images/courses/LITTLEBOBS.jpg";
import MakerMS from "@/assets/images/courses/MakerMS.jpg";
import MakerSS from "@/assets/images/courses/MakerSS.jpg";
import Mindstorms from "@/assets/images/courses/Mindstorms.jpg";
import Phonephysics from "@/assets/images/courses/Phonephysics.jpg";
import RenewableEnergy from "@/assets/images/courses/RenewableEnergy.jpg";
import RoboticCodingBasic from "@/assets/images/courses/Robotic&CodingBasic.jpg";
import RoboticsAdvanced from "@/assets/images/courses/Robotics&Advanced.jpg";
import RoboticsCodingMedium from "@/assets/images/courses/Robotics&CodingMedium.jpg";
import Scratch from "@/assets/images/courses/Scratch.jpg";
import SPM from "@/assets/images/courses/SPM.jpg";
import STEMAdvanced from "@/assets/images/courses/STEMAdvanced.jpg";
import STEMBasic3 from "@/assets/images/courses/STEMBasic3.jpg";
import STEMMedium from "@/assets/images/courses/STEMMedium.jpg";
import MicroBit1 from "@/assets/images/courses/MicroBit1.jpg";

import WeeDoo2 from "@/assets/images/cardimg1.png";
import printing2 from "@/assets/images/courses/3Dprintind2.png";
import arduino2 from "@/assets/images/courses/Arduino2.png";
import Brainobrain2 from "@/assets/images/courses/Brainobrain2.png";
import CodeOrg2 from "@/assets/images/courses/CodeOrg2.png";
import DaVinci2 from "@/assets/images/courses/DaVinci2.png";
import Hands2Mind2 from "@/assets/images/courses/Hands2Mind2.png";
import KurseShkollore2 from "@/assets/images/courses/KurseShkollore2.png";
import LITTLEBOBS2 from "@/assets/images/courses/LITTLEBOBS2.png";
import MakerMS2 from "@/assets/images/courses/MakerMS2.png";
import MakerSS2 from "@/assets/images/courses/MakerSS2.png";
import Mindstorms2 from "@/assets/images/courses/Mindstorms2.png";
import Phonephysics2 from "@/assets/images/courses/Phonephysics2.png";
import RenewableEnergy2 from "@/assets/images/courses/RenewableEnergy2.png";
import RoboticCodingBasic2 from "@/assets/images/courses/Robotic&CodingBasic2.png";
import RoboticsAdvanced2 from "@/assets/images/courses/Robotics&Advanced2.png";
import RoboticsCodingMedium2 from "@/assets/images/courses/Robotics&CodingMedium2.png";
import Scratch2 from "@/assets/images/courses/Scratch2.png";
import SPM2 from "@/assets/images/courses/SPM2.png";
import STEMAdvanced2 from "@/assets/images/courses/STEMAdvanced2.png";
import STEMBasic32 from "@/assets/images/courses/STEMBasic32.png";
import STEMMedium2 from "@/assets/images/courses/STEMMedium2.png";
import MicroBit12 from "@/assets/images/courses/MicroBit2.png";

const data = [
    {
        id: 0,
        age:"5",
        image: STEMBasic3,
        image2: STEMBasic32,
        title: "STEM Basic",
        categoryId: 1,
        ageGroupId: 1,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "90 min.",
        price: "1800",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht, 15 Janar",
        time: "Shtator-Dhjetor, Shkurt-Maj",
        teacher: "",
        CODE: "ESM-WS",
        shortDesc: "Me STEM Basic fëmijët sillen si shkencëtarë të rinj.",
        longDesc: [`Programi STEM Basic përfshinë konceptet bazë shkencore, kryesisht fizikës dhe inxhnierisë së makinave të thjeshta. Konceptet mësohen nëpërmjet lojës, shpjegimit në dy gjuhë (anglisht dhe shqip) dhe fletave të punës që nxënësit i kanë për çdo orë.`],
        purpose: [`Qëllimi i kursit është zhvillimi i motorikës së nxënësve, zhvillimi i mendimit kritik nëpërmjet pyetjeve të llojit "Çka nëse...?". Nxënësit nëpërmjet hulumtimit, testimit të pajisjeve të ndërtura nga vetë ata, mbledhja dhe prezentimi i rezultateve të arritura ata ndjehen si shkenctarë të vëretetë.`],
        skills: [`Vëzhgimi dhe hulumtimi.`, `Të kuptuarit e parimeve mekanike.`, `Zgjidhja e detyrave "tasks".`],
    },
    {
        id: 1,
        age:"6",
        image: Hands2Mind,
        image2: Hands2Mind2,
        title: "Hands2Mind",
        categoryId: 6,
        ageGroupId: 2,
        durationMonths: "8",
        durationWeek: "32",
        durationHour: "240 min.",
        price: "3000",
        extracost: "Pajisjet e Brainobrain dhe Librat",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "H2M-W",
        shortDesc: "Kombinim i LEGO Education dhe programit të licencuar ndërkombëtarisht Brainobrain",
        longDesc: [`Programi është dizajnuar në koordinim me ekspert të fushës së pedagogjisë. Ky program është kombinim i LEGO Education dhe programit të licencuar ndërkombëtarisht Brainobrain. Ngjyrat e kubeve, lëvizja e gishtave, përdorimi i forcës së duarve për të puthitur kubet, qëndrimi në këmbë, aktivitetit fizik gjatë testimit të pajisjeve të ndërtuar nga vetë ata,... janë elementet kryesore që karakterizojnë këtët kurs.`],
        purpose: [`Qëllimi primar i kursit është zhvillimi i motorikës fine, aritmetikës mentale, duke aplikuar Vak Metodën, NLP dhe metodologjinë 4C të zhvilluar nga LEGO Education.`],
        skills: [`Shprehi për punë sistematike.`,`Multitasking - kryerja e veprimeve të ndryshme në të njejtën kohë.`,`Vizualizimi dhe kreativiteti.`,`Lidershipi, vendosmëria, vetëndërgjegjja.`,`Memoria e zhvilluar fotografike.`,`Shkathtësi për të punuar në grup.`],
    },
    {
        
        id: 2,
        age:"6",
        image: STEMMedium,
        image2: STEMMedium2,
        title: "STEM Medium",
        categoryId: 1,
        ageGroupId: 2,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "90 min.",
        price: "1800",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Janar",
        time: "Shkurt-Maj",
        teacher: "",
        CODE: "BME-S",
        shortDesc: "Një zgjidhje tërheqëse dhe praktike, që inkurajon nxënësit të zbulojnë shkencat fizike në veprim.",
        longDesc:[ `Nxënësit angazhohen në eksplorimin e shkencave fizike brenda një konteksti sportiv. Kursi ndihmon në nxitjen e njohjes së koncepteve si forca, lëvizja dhe bashkëveprimin duke ofruar përvoja të lehta mësimore, praktike pa pasur nevojë për teknologji.`],
        purpose: [`Zhvillim i sensit për mësim gjatë gjithë jetës (LLL) në STEM me ose pa përdorim të teknologjive përcjellëse dhe nxjerrjen në pah të shkathtësive të nxënësve, nëpërmjet aktivitetit praktik.`],
        skills: [`Përvetësimi i njohurive në fushën e hulumtimit shkencor.`,`Mësimi i procesit të ndërtimit.`,`Mësimi dhe përdorimi i fjalorit shkencor.`,`Testimi, matja, mbledhja e të dhënave dhe interpretimi i rezultateve.`],
    },
    {
       
        id: 3,
        age:"7",
        image: RoboticCodingBasic,
        image2: RoboticCodingBasic2,
        title: "Robotics&Coding Basic",
        ageGroupId: 2,
        categoryId: 2,
        durationMonths: "8",
        durationWeek: "32",
        durationHour: "90 min.",
        price: "1800",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "SE-W",
        shortDesc: "Rezultate të forta të të mësuarit të STEAM, zhvillim të njohurive dhe shkathtësive të shekullit të 21-të",
        longDesc: [`Kursi është një sistem mësimi praktik dhe intuitiv, gjithëpërfshirës dhe shumë i adaptueshëm. Ai nxjerr në pah aftësitë e nxënësve për të ardhmen dhe i bën ata "life-long learners", duke u dhënë atyre mundësi të pakufizuara për të mësuar STEAM në mënyrë praktike dhe argëtuese.`],
        purpose: [`Aktiviteti u mundësojnë nxënësve të punojnë si shkencëtarë, dizajnerë dhe inxhinierë të rinj. Nxënësit  zhvillojnë aftësi të tilla si zgjidhja krijuese e problemeve, komunikimi i ideve dhe puna në ekip. Ata përdorin metodën shkencore përmes vëzhgimit, arsyetimit, parashikimit dhe mendimit kritik.`],
        skills: [`Aftësi efikase për zgjidhjen e problemeve përmes zbërthimit dhe të menduarit algoritmik.`,`Aftësi pune me variablat, vargjet e të dhënave dhe të dhënat cloud.`,`Aftësi të të menduarit kritik dhe zhvillim të shkathtësive për profesionet e së ardhmes.`],
    },
    {
        
        id: 4,
        age:"8",
        image: WeeDoo1,
        image2: WeeDoo2,
        title: "Simple Machines",
        categoryId: 1,
        ageGroupId: 2,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "90 min.",
        price: "1800",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht, 15 Janar",
        time: "Shtator-Dhjetor, Shkurt-Maj",
        teacher: "",
        CODE: "SM-WS",
        shortDesc: "Me Simple Machines nxënësit kuptojnë funksionimin  dhe përdorimin e makinave të thjeshta",
        longDesc: [`Kursi Simple Machines trajton parimin e punës dhe zbatimin praktik të makinave të thjeshta: ingranzheve, levave, makarat dhe rrotat dhe boshtet. Me ndihmën e trajnuesit, nxënësit ushtrohen që të njohin dhe përdorin fajlorin teknik.`],
        purpose: [`Aktiviteti u mundësojnë nxënësve të punojnë si shkencëtarë, dizajnerë dhe inxhinierë të rinj. Nxënësit  zhvillojnë aftësi të tilla si zgjidhja krijuese e problemeve, komunikimi i ideve dhe puna në ekip. Ata përdorin metodën shkencore përmes vëzhgimit, arsyetimit, parashikimit dhe mendimit kritik.`],
        skills: [`Përvetësimi i njohurive në fushën e hulumtimit shkencor.`,`Mësimi i procesit të ndërtimit.`,`Mësimi dhe përdorimi i fjalorit shkencor.`,`Testimi, matja, mbledhja e të dhënave dhe interpretimi i rezultateve.`],
    },
    {
        
        id: 5,
        age:"12",
        image: STEMAdvanced,
        image2: STEMAdvanced2,
        title: "STEM Advanced",
        ageGroupId: 3,
        categoryId: 1,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "120 min.",
        price: "2000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Maj",
        time: "Qershor-Gusht",
        teacher: "",
        CODE: "BMP-SS",
        shortDesc: "Një zgjidhje tërheqëse dhe praktike, që inkurajon nxënësit të zbulojnë shkencat fizike në veprim.",
        longDesc: [`Nxënësit angazhohen në eksplorimin e shkencave fizike brenda një konteksti sportiv. Kursi ndihmon në nxitjen e njohjes së koncepteve si forca, lëvizja dhe bashkëveprimin duke ofruar përvoja të lehta mësimore, praktike pa pasur nevojë për teknologji.`],
        purpose: [`Zhvillim i sensit për mësim gjatë gjithë jetës (LLL) në STEM me ose pa përdorim të teknologjive përcjellëse dhe nxjerrjen në pah të shkathtësive të nxënësve, nëpërmjet aktivitetit praktik.`],
        skills: [`Përvetësimi i njohurive në fushën e hulumtimit shkencor.`,`Mësimi i procesit të ndërtimit`,`Mësimi dhe përdorimi i fjalorit shkencor.`,`Testimi, matja, mbledhja e të dhënave dhe interpretimi i rezultateve.`],
    },
    {
        
        id: 6,
        age:"9-10",
        image: RoboticsCodingMedium,
        image2: RoboticsCodingMedium2,
        title: "Robotics&Coding Medium",
        categoryId: 2,
        ageGroupId: 2,
        durationMonths: "8",
        durationWeek: "32",
        durationHour: "90 min.",
        price: "1800",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "WD-W",
        shortDesc: "Mësim i robotikës dhe aftësive për kodim nëpërmjet projekteve të fushave të ndryshme",
        longDesc: [`Robotics and Coding - Medium është zhvilluar për të angazhuar dhe motivuar filloristët për të mësuar lëndë të lidhura me shkencën dhe inxhinierinë. Kjo bëhet përmes përdorimit të modeleve të motorizuara LEGO- ROBOTËT dhe programimit të thjeshtë-CODE.`],
        purpose: [`Qëllimi i kursit është mbështetja e nxënësve që në mënyrë praktike, me aktivizim të trurit (HANDS-ON and MINDS-ON) të parashtrojnë pyetje dhe me ndihmën e robotëve që vetë i ndërtojnë dhe programojnë, të gjejnë zgjidhje për problemet e jetës reale.`],
        skills: [`Shkathtësi hulumtimi, modelimi dhe dizajnim të zgjidhjeve për sfida reale.`,`Angazhim real në zgjidhjen e sfidave nëpërmjet kodimit dhe robotikës.`,`Zhvillim i "mendimit kompjuterik" dhe shkathtës themelore të programimit.`,`Aftësi të lertë për "teamwork" dhe prezantimit të rezultateve.`,`Mendimi kritik për zgjidhje të problemeve reale.`],
    },
    {
        
        id: 7,
        age:"13",
        image: RoboticsAdvanced,
        image2: RoboticsAdvanced2,
        title: "Robotics&Coding Advanced",
        categoryId: 2,
        ageGroupId: 3,
        durationMonths: "8",
        durationWeek: "32",
        durationHour: "120 min.",
        price: "2000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "SP-W",
        shortDesc: " Nga projektet hyrëse-të lehta e deri te mundësitë e pakufishme të dizajnit krijues",
        longDesc: [`Robotics and Coding - Advanced angazhon vazhdimisht nxënësit që përmes aktiviteteve mësimore dhe lojës, për të menduar në mënyrë kritike dhe për të zgjidhur probleme komplekse, pavarësisht nga niveli i tyre i njohurive. Nga projektet hyrëse-të lehta e deri te mundësitë e pakufishme të dizajnit krijues, duke përfshirë opsionin për të eksploruar kodimin e bazuar në tekst me Python, Progremi i ndihmon nxënësit të mësojnë konceptet e STEAM në mënyrë thelbësore STEAM dhe shkathtësitë e shekullit të 21-të që nevojiten për t'u bërë mendjet novatore të së nesërmes... ndërkohë që argëtohen!`],
        purpose:[`Fuqizimi i nxënësve për të marrë pjesë në botën e garave STEM është njëri nga qëllimet kryesore të kësaj pakoje. Garat e robotikës ofrojnë sfida praktike argëtuese që i emocionoj nënxënësit, krijojnë vetëbesim dhe zhvillojnë llojin e aftësive të nevojshme për suksesin në botën reale. Ndërsa kurikulat e dendura hapin horizontet e tyre, duke i motivuar për profesionet e së ardhmes, por në të njejtën kohë duke përfshirë edhe procesin e digjitalizimit në fushat përkatëse.`],
        skills: [`Aftësi të projektimit inxhinierik në çdo hap të procesit.`,`Aftësi efikase për zgjidhjen e problemeve përmes zbërthimit dhe të menduarit algoritmik.`,`Hartimi i projekteve që kombinojnë komponentët e harduerit dhe softuerit për të mbledhur dhe shkëmbyer të dhëna.`,`Aftësi pune me variablat, vargjet e të dhënave dhe të dhënat cloud.`,`Aftësi të të menduarit kritik dhe zhvillim të shkathtësive për profesionet e së ardhmes.`],
    },
    {
        
        id: 8,
        age:"7-14",
        image: Brainobrain,
        image2: Brainobrain2,
        title: "BRAINOBRAIN",
        categoryId: 4,
        ageGroupId: 2,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "120 min.",
        price: "1800",
        extracost: "Pajisjet e Brainobrain dhe Librat",
        registration: "15 Shtator, 15 Janar, 15 Maj",
        time: "Tetor-Dhjetor Shkurt-Prill Qershor-Gusht",
        teacher: "",
        CODE: "B-WSS",
        shortDesc: "Baza e BRAINOBRAIN programit janë aritmetika mentale e Abakusit, VAK metoda e mësimit dhe NLP.",
        longDesc: [`Baza e BRAINOBRAIN programit janë aritmetika mentale e Abakusit, VAK Metoda e mësimit dhe NLP-ja (Programimi neuro-liguistik). Fëmijët mësohen si ta vizualizojnë Abakusin, kështu që ai nuk u nevojitet më fizikisht – fëmijët krijojnë shabllon imagjinativ në kokën e tyre. NLP-ja siguron kuptimin e asaj se si ne mendojmë dhe veprojmë, që të na ndihmojë të mësohemi dhe të bëjmë ndryshime përkatëse. VAK-Disa mësojnë duke shikuar (në mënyrë vizuale), të tjerët duke dëgjuar (audio), ndërsa persona të tretë duke ndjerë (në mënyrë kinestetike).`],
        purpose: [`Fëmija ta zgjojë tërësisht gjenialitetin e tij dhe të arrijë më shumë qëllime në jetë duke i shfrytëzuar maksimalisht aftësitë mentale. Me Brainobrain fëmijët i zhvillojnë këto aftësi: Shpejtësia dhe efikasiteti gjatë mësimit, përmirësimi i përqendrimit. Memoria, vizualizimi, vetëbesimi, vetëmësimi, zellshmëria dhe vetëdisiplina. Shpejtësia gjatë përpunimit të informatave, aftësitë e përmirësuara vizuale dhe audio.`],
        skills: [`Vizualizimi dhe kreativiteti`,`Të qenët i drejtuar, i shpejtë dhe i saktë`,`Aftësitë e shkëlqyeshme aritmetike`,`Lidershipi, vendosmëria, vetëndërgjegjja`,`Memoria e zhvilluar fotografike`,`Zhvillimi i aftësive multitasking`],
    },
    {
        
        id: 9,
        age:"4-6",
        image: LITTLEBOBS,
        image2: LITTLEBOBS2,
        title: "LITTLEBOBS",
        categoryId: 4,
        ageGroupId: 1,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "120 min.",
        price: "1800",
        extracost: "Pajisjet e Brainobrain dhe Librat",
        registration: "15 Shtator, 15 Janar, 15 Maj",
        time: "Tetor-Dhjetor Shkurt-Prill Qershor-Gusht",
        teacher: "",
        CODE: "B-WSS",
        shortDesc: "Baza e BRAINOBRAIN programit janë aritmetika mentale e Abakusit, VAK metoda e mësimit dhe NLP.. ",
        longDesc: [`Baza e BRAINOBRAIN programit janë aritmetika mentale e Abakusit, VAK Metoda e mësimit dhe NLP-ja (Programimi neuro-liguistik). Fëmijët mësohen si ta vizualizojnë Abakusin, kështu që ai nuk u nevojitet më fizikisht – fëmijët krijojnë shabllon imagjinativ në kokën e tyre. NLP-ja siguron kuptimin e asaj se si ne mendojmë dhe veprojmë, që të na ndihmojë të mësohemi dhe të bëjmë ndryshime përkatëse. VAK-Disa mësojnë duke shikuar (në mënyrë vizuale), të tjerët duke dëgjuar (audio), ndërsa persona të tretë duke ndjerë (në mënyrë kinestetike).`],
        purpose: [`Fëmija ta zgjojë tërësisht gjenialitetin e tij dhe të arrijë më shumë qëllime në jetë duke i shfrytëzuar maksimalisht aftësitë mentale. Me Brainobrain fëmijët i zhvillojnë këto aftësi: Shpejtësia dhe efikasiteti gjatë mësimit, përmirësimi i përqendrimit. Memoria, vizualizimi, vetëbesimi, vetëmësimi, zellshmëria dhe vetëdisiplina. Shpejtësia gjatë përpunimit të informatave, aftësitë e përmirësuara vizuale dhe audio.`],
        skills: [`Vizualizimi dhe kreativiteti`,`Të qenët i drejtuar, i shpejtë dhe i saktë`,`Aftësitë e shkëlqyeshme aritmetike`,`Lidershipi, vendosmëria, vetëndërgjegjja`,`Memoria e zhvilluar fotografike`,`Zhvillimi i aftësive multitasking`],
    },
    {
        
        id: 10,
        age:"11-12",
        image: SPM,
        image2: SPM2,
        title: "Simple and Powered Machines",
        categoryId: 1,
        ageGroupId: 3,
        durationMonths: "8",
        durationWeek: "32",
        durationHour: "120 min.",
        price: "1800",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "SPM-W",
        shortDesc: "Fëmijët inkurajohen të përfshijnë veten në hetime të botës reale dhe zgjidhjen e problemeve.",
        longDesc: [`Paketa u mundëson fëmijëve të punojnë si shkencëtarë, inxhinierë dhe dizajnerë të rinj duke u siguruar atyre mjedis, mjete dhe detyra të bazuara në STEM. Fëmijët inkurajohen të përfshijnë veten në hetime të botës reale dhe zgjidhjen e problemeve. Ata bëjnë supozime dhe parashikime. Ata hartojnë dhe bëjnë modele dhe më pas vëzhgojnë sjelljen e këtyre modeleve; ato reflektojnë dhe ridizenjojnë, dhe më pas regjistrojnë dhe prezantojnë gjetjet e tyre.`],
        purpose: [`Qëllimet e programit në ndërtimin e personaliteteve me aftësi shkencore ndaj problemeve reale të tilla si: të mendojn në mënyrë krijuese, të shpjegojnë se si funksionojnë gjërat, të krijojnë lidhje midis shkakut dhe pasojës, të dizajnojnë dhe të krijojnë mjete që përmbushin kritere specifike, të provojnë ide të reja duke përdorur rezultatet nga vëzhgimet dhe matjet, të bëni pyetje që mund të hetohen shkencërisht, ...`],
        skills: [`Zhvillimi dhe përdorimi i modeleve`,`Planifikimi dhe kryerja e hulumtimit`,`Analizimi dhe interpretimi i të dhënave`,`Përdorimi i matematikës dhe të të menduarit kompjuterik`,`Ndërtimi i shpjegimeve (për shkencën) dhe dizajnimi i zgjidhjeve (për inxhinierinë)`,`Angazhimi në argumentet e fituara nga evidentimet`,`Marrja, vlerësimi dhe komunikimi i informacionit`],
        },
    {
        
        id: 11,
        age:"13-14",
        image: Mindstorms,
        image2: Mindstorms2,
        title: "MINDSTORMS",
        ageGroupId: 3,
        categoryId: 2,
        durationMonths: "8",
        durationWeek: "32",
        durationHour: "120 min.",
        price: "2000",
        extracost: "",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "EV3-W",
        shortDesc: "EV3 u mundëson nxënësve të ndërtojnë, programojnë, dhe eksperimentojnë, pa njohuri paraprake ose përvojë rreth programimit apo ndërtimit.",
        longDesc: [`MINDSTORMS Education EV3 Software fokusohet kryesisht në inxhinieri, teknologji dhe shkenca kompjuterike. Megjithatë, kjo pako gjithashtu ofron një mundësi për të integruar aktivitete ndër-kurrikulare, të tilla si shkenca dhe matematika, kur nxënësit i përfundojnë sfidat. Natyra e integruar dhe motivuese e softuerit EV3 u mundëson nxënësve të ndërtojnë, programojnë, dhe eksperimentojnë, pa njohuri paraprake ose përvojë rreth programimit apo ndërtimit.`],
        purpose: [`Qëllimet e programit mund të përgjithësohen në disa pika të veçanta; ju ndihmon fëmijëve që të plotësojnë pasionin e tyre për STEM profilet,  programet e thella të robotikës duke rikthyer vetëbesimin fuqizojnë ndjenjën e lidershipit të së nesërmes, mësojnë nxënësit si të komunikojnë dhe ndërveprojnë në platforma të ndryshme teknologjike,  fuqizojnë punën ekipore dhe nxisin involvimin e nxënësve në sfidat shoqërore.`],
        skills: ["Dizajnimi dhe ndërtimi i robotëve të programueshëm duke përdorur motorë, sensorë, ingranazhe, rrota dhe akse, dhe përbërës të tjerë teknikë.","Kuptimi dhe interpretimi i vizatimeve dy-dimensionale për të krijuar modele tre-dimensionale.","Ndërtimi, testimi, zgjidhja e problemeve dhe rishikimi i modeleve për të përmirësuar performancën e robotit.","Përvojë praktike duke përdorur koncepte matematikore të tilla si vlerësimi dhe matja e distancës, kohës dhe shpejtësisë.","Komunikim efektiv duke përdorur gjuhë shkencore dhe teknike."],
    },
    {
        
        id: 12,
        age:"8-10",
        image: DaVinci,
        image2: DaVinci2,
        title: "Da Vinci",
        ageGroupId: 2,
        categoryId: 6,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "120 min.",
        price: "2000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Shtator, 15 Janar, 15 Maj",
        time: "Tetor-Dhjetor, Shkurt-Prill, Qershor-Gusht",
        teacher: "",
        CODE: "DV-WSS",
        shortDesc: "Fëmijët futen në rolin e arkitektit, dizajnuesit të robotëve dhe protezave të ndryshme",
        longDesc: [`Programi Da Vinci është dizajnuar që të ndërthur konceptet shkencore me artin. Nxënësit vizatojnë, modelojnë dhe testojnë produktet e tyre. Fëmijët futen në rolin e arkitektit, dizajnuesit të robotëve dhe protezave të ndryshme, por gjatë punës së tyre do të marrin në shqyrtim edhe logjikën shkencore dhe inxhinierike.`],
        purpose: [`Duke shprehur kuptimin e tyre konceptual duke përdorur artin, nxënësit  përmirësojnë kapacitetin e tyre njohës, të pasurojnë zhvillimin e tyre kulturor dhe të sjellin kënaqësi në procesin e të mësuarit të shkencës dhe teknologjisë moderne në përgjithësi. Nëpërmjet aktivitetit praktik nxënësit që në fazë të hershme ndërtojë një vizion për rolin e artit dhe krijimtarisë në arritjen e ideve inovative shkencore dhe teknologjike, të cilat drejtojnë edhe ekonomitë e vendeve të zhvilluara.`],
        skills: [`Zhvillim i sensit për perceptimin e botës reale dhe zhvillim të shkathtësive për vënie në funksion të elementeve të ndryshme në kontekstin praktik.`,`Orientim praktik i talenteve të fshehura në botën e nxënësit.`,`Përvetësim të koncepteve bazë artistike të lidhura me botën fizike.`],
    },
    {
        
        id: 13,
        age:"11-12",
        image: RenewableEnergy,
        image2: RenewableEnergy2,
        title: "Renewable Energy",
        categoryId: 1,
        ageGroupId: 3,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "120 min.",
        price: "2500",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Shtator, 15 Janar, 15 Maj",
        time: "Tetor-Dhjetor, Shkurt-Prill, Qershor-Gusht",
        teacher: "",
        CODE: "RE-WSS",
        shortDesc: "Energji pozitive për punë shkencore!",
        longDesc: [`Kursi promovon një mjedis sfidues në klasë dhe angazhon nxënësit në mënyrë aktive në kërkime shkencore, arsyetim dhe të menduarit kritik. Ata sfidohen të bëjnë supozime dhe parashikime, duke bashkuar përvojat dhe njohuritë e tyre të shumta nga lëndë të ndryshme. Ata përdorin aftësitë, kreativitetin dhe intuitën e tyre për të krijuar në mënyrë aktive kuptim të ri.`],
        purpose: [`Qëllimi i kursit është zhvillimi i njohurive bazike për konceptet e energjisë së renovueshme, e cila në fakt shërben që nxënësit të arrijnë të krijojnë shprehi praktike për punë sistematike shkencore.  Atyre u kërkohet të dizajnojnë dhe ridizajnojnë, të ndërtojnë dhe reflektojnë mbi modelet. Atyre u kërkohet gjithashtu të vëzhgojnë dhe shpjegojnë se si ndryshimi i variablave ndikojnë në këto modele dhe më pas të regjistrojnë dhe paraqesin gjetjet e tyre.`],
        skills: [`Përvojë për punë sistematike shkencore.`,`Zhvillim të shkathtësive për manipulim me instrumente dhe të dhënat e fituara gjatë procesit të hulumtimit.`,`Zhvillim të performansës për arsyetime dhe prezantime logjike të punës hulumtuese.`],
    },
    {
        
        id: 14,
        age:"6-7",
        image: WeeDoo1,
        image2: WeeDoo2,
        title: "STEM Challenge",
        categoryId: 1,
        ageGroupId: 2,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "120 min.",
        price: "2500",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Shtator, 15 Janar, 15 Maj",
        time: "Tetor-Dhjetor, Shkurt-Prill, Qershor-Gusht",
        teacher: "",
        CODE: "SCH-WSS",
        shortDesc: "Nxënësit realizojnë eksperimente me mjete të thjeshta  nga lëmia e shkencave natyrore dhe inxhinieria",
        longDesc: [`Nxënësit realizojnë eksperimente me mjete të thjeshta dhe zgjidhin detyra me ndihmën e softwareve të ndryshëm nga lëmia e shkencave natyrore dhe inxhinieria. Pas një periudhe të adaptimit, ata sukseset e tyre i prezantojnë para kamerës, duke zhvilluar shkathtësitë prezentuese me logjikë dhe fjalor të përshtatshëm shkencor.`],
        purpose: [`Qëllimi primar i pragramit është aftësimi i nxënësve për mësim të bazuar në projekte, e cila qasje stimulon të mësuarit e logjikshëm dhe zhvillon shkathtësitë motorike për punë laboratorike. Angazhimi praktik i nxënësve, puna në grup, tejkalimi i problemeve eventuale, kërkesa për të prezantuar projektet rrit edhe vetbesimin e tyre.`],
        skills: [`Zhvillim të shkathtësive për punë të organizuar laboratorike.`,`Prezantim me sens të problematikave të caktuara.`,`Rikthimin e vetëbesimit dhe ndjenjës se "unë e bëra këtë"!`],
    },
    {
        
        id: 15,
        age:"5-10",
        image: CodeOrg,
        image2: CodeOrg2,
        title: "Code.org",
        ageGroupId: 2,
        categoryId: 3,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "2 x 45 min.",
        price: "1800",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Shtator, 15 Janar, 15 Maj",
        time: "Tetor-Dhjetor, Shkurt-Prill, Qershor-Gusht",
        teacher: "",
        CODE: "CODE-WSS",
        shortDesc: "Çdokush mund të mësojë shkenca kompjuterike",
        longDesc: [`Kursi është projektuar kryesisht për një audiencë të shkollës fillore, por edhe për nxënës për klasa më të larta që e shohin atë gjithashtu si një pikënisje të dobishme. Kjo kurrikulë është zhvilluar për përdorim nga të gjithë edukatorët e fëmijëve të vegjël, pa njohuri paraprake të shkencës kompjuterike, pasi planet  e ofruara mësimore janë të qarta dhe të detajuara që mund të përshtaten për situata të ndryshme.`],
        purpose: [`Code.org® është një organizatë jo-fitimprurëse e dedikuar për zgjerimin e qasjes dhe mësimit të shkencave kompjuterike në shkolla dhe rritjen e pjesëmarrjes të femrave të reja dhe nxënsëve nga grupe pak të përfaqësuara. Vizioni I code.org është që çdo nxënës, në çdo shkollë të ketë mundësinë të mësojë shkenca kompjuterike si pjesë e edukimit të tyre bazik K-12.`],
        skills: [`Nxënësit do të zhvillojnë aftësitë e një shkencëtari kompjuterik përmes zhvillimit të praktikave të të menduarit kompjuterik:`,`Kreativiteti`,`Bashkëpunimi`,`Komunikimi`,`Këmbëngulja`,`Zgjidhja e problemeve`],
    },
    {
        
        id: 16,
        age:"7-10",
        image: MicroBit1,
        image2: MicroBit12,
        title: "Micro:bit",
        ageGroupId: 2,
        categoryId: 3,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "120 min.",
        price: "2000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Shtator, 15 Janar, 15 Maj",
        time: "Tetor-Dhjetor Shkurt-Prill Qershor-Gusht",
        teacher: "",
        CODE: "MB-WSS",
        shortDesc: "Ngritni nivelin e kreativitetit të fëmijëve tuaj!",
        longDesc: [`BBC micro:bit është një kompjuter me madhësi xhepi që ju prezanton me mënyrën se si softueri dhe hardueri funksionojnë së bashku. Ka një ekran me dritë LED, butona, sensorë dhe shumë veçori hyrëse/dalëse që, kur programohen, e lejojnë atë të ndërveprojë me ju dhe botën tuaj.`],
        purpose: [`Micro:bit ju ndihmon nxënësve të kuptojnë se si funksionojnë kompjuterët. Kur shkruani në laptop ose prekni ekranin në telefonin tuaj, jeni duke përdorur një pajisje hyrëse. Inputet i lejojnë kompjuterët të ndiejnë gjërat që ndodhin në botën reale, kështu që ata mund të veprojnë mbi këtë dhe të bëjnë diçka të ndodhë, zakonisht në një dalje si një ekran ose kufje.`],
        skills: [`Nxënësit mund të mësojnë aftësitë e zgjidhjes së problemeve dhe të hartimit të projektit, të tilla si arsyetimi logjik, korrigjimi i problemeve, zhvillimi i ideve nga konceptimi fillestar deri në projektin e përfunduar dhe ruajtja e fokusit. Ata gjithashtu mund të mësojnë koncepte specifike programimi, të tilla si sekuenca, përsëritja, kushtëzimi, variablat dhe strukturat e të dhënave.`],
    },
    {
        
        id: 17,
        age:"11-14",
        image: MicroBit1,
        image2: MicroBit12,
        title: "PBL Micro:bit",
        ageGroupId: 3,
        categoryId: 7,
        durationMonths: "3",
        durationWeek: "12",
        durationHour: "120 min.",
        price: "2000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Shtator, 15 Janar, 15 Maj",
        time: "Tetor-Dhjetor, Shkurt-Prill, Qershor-Gusht",
        teacher: "",
        CODE: "PMB-WSS",
        shortDesc: "Ngritni nivelin e kreativitetit të fëmijëve tuaj!",
        longDesc: [`BBC micro:bit është një kompjuter me madhësi xhepi që ju prezanton me mënyrën se si softueri dhe hardueri funksionojnë së bashku. Ka një ekran me dritë LED, butona, sensorë dhe shumë veçori hyrëse/dalëse që, kur programohen, e lejojnë atë të ndërveprojë me ju dhe botën tuaj.`],
        purpose: [`Qëllimi i PBL Micro:bit është angazhimi i nxënësve në projekte reale. Nëpërmjet projekteve, sfidave dhe nevojës për optimizimin e funksioneve të ndryshme nxënësit zhvillojnë shkathtësitë dhe aftësinë për punë praktike.`],
        skills: [`Nxënësit mund të mësojnë aftësitë e zgjidhjes së problemeve dhe të hartimit të projektit, të tilla si arsyetimi logjik, korrigjimi i problemeve, zhvillimi i ideve nga konceptimi fillestar deri në projektin e përfunduar dhe ruajtja e fokusit. Ata gjithashtu mund të mësojnë koncepte specifike programimi, të tilla si sekuenca, përsëritja, kushtëzimi, variablat dhe strukturat e të dhënave.`],
    },
    {
        
        id: 18,
        age:"8-10",
        image: Scratch,
        image2: Scratch2,
        title: "Scratch",
        categoryId: 3,
        ageGroupId: 2,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "90 min.",
        price: "1500",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht, 15 Janar",
        time: "Shtator-Dhjetor Shkurt-Maj",
        teacher: "",
        CODE: "SC-WS",
        shortDesc: "Scratch promovon të menduarit kopjuterik dhe aftësitë për zgjidhjen e problemeve, mësimnxënie krijuese, vetë-shprehje dhe bashkëpunim...",
        longDesc: [`Scratch është komuniteti më i madh i kodimit në botë për fëmijët dhe një gjuhë kodimi me një interface të thjeshtë vizuale që u lejon të rinjve të krijojnë tregime digjitale, lojëra dhe animacione.`],
        purpose: [`Scratch promovon të menduarit kompjuterik dhe aftësitë për zgjidhjen e problemeve; mësimdhënie dhe mësimnxënie krijuese; vetë-shprehje dhe bashkëpunim; dhe barazinë në informatikë.`],
        skills: [`Nxënësit mund të mësojnë aftësitë e zgjidhjes së problemeve dhe të hartimit të projektit, të tilla si arsyetimi logjik, korrigjimi i problemeve, zhvillimi i ideve nga konceptimi fillestar deri në projektin e përfunduar dhe ruajtja e fokusit. Ata gjithashtu mund të mësojnë koncepte specifike programimi, të tilla si sekuenca, përsëritja, kushtëzimi, variablat dhe strukturat e të dhënave.`],
    },
    {
        
        id: 19,
        age:"11-14",
        image: Scratch,
        image2: Scratch2,
        title: "PBL Scratch",
        ageGroupId: 3,
        categoryId: 7,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "90 min.",
        price: "1500",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht, 15 Janar",
        time: "Shtator-Dhjetor, Shkurt-Maj",
        teacher: "",
        CODE: "PSC-WS",
        shortDesc: "Scratch promovon të menduarit kopjuterik dhe aftësitë për zgjidhjen e problemeve, mësimnxënie krijuese, vetë-shprehje dhe bashkëpunim...",
        longDesc:[`Scratch është komuniteti më i madh i kodimit në botë për fëmijët dhe një gjuhë kodimi me një interface të thjeshtë vizuale që u lejon të rinjve të krijojnë tregime digjitale, lojëra dhe animacione.`],
        purpose: [`Scratch promovon të menduarit kompjuterik dhe aftësitë për zgjidhjen e problemeve; mësimdhënie dhe mësimnxënie krijuese; vetë-shprehje dhe bashkëpunim; dhe barazinë në informatikë.`],
        skills: [`Nxënësit mund të mësojnë aftësitë e zgjidhjes së problemeve dhe të hartimit të projektit, të tilla si arsyetimi logjik, korrigjimi i problemeve, zhvillimi i ideve nga konceptimi fillestar deri në projektin e përfunduar dhe ruajtja e fokusit. Ata gjithashtu mund të mësojnë koncepte specifike programimi, të tilla si sekuenca, përsëritja, kushtëzimi, variablat dhe strukturat e të dhënave.`],
    },
    {
        
        id: 20,
        age:"15-18",
        image: arduino,
        image2: arduino2,
        title: "Arduino",
        ageGroupId: 4,
        categoryId: 7,
        durationMonths: "8",
        durationWeek: "32",
        durationHour: "120 min.",
        price: "2500",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "ARD-W",
        shortDesc: "Empower the next generations of students to be the disruptors of the future",
        longDesc: [`Arduino projekton, prodhon dhe mbështet pajisje dhe softuer elektronikë, duke i lejuar njerëzit në mbarë botën të kenë qasje me lehtësi tek teknologjitë e avancuara që ndërveprojnë me botën fizike. Produktet janë të drejtpërdrejta, të thjeshta dhe të fuqishme, të gatshme për të kënaqur nevojat e përdoruesve nga studentët tek prodhuesit dhe deri te zhvilluesit profesionistë.`],
        purpose: [`Arduino fuqizon mendjet krijuese për të zotëruar teknologjinë dhe për të zgjidhur në mënyrë intuitive problemet e përditshme. Platforma thjeshton përdorimin e mjeteve të tjera komplekse. Për shembull, programimi i një pajisjeje IoT të lidhur mirë është vetëm disa klikime larg me përdorimin e Arduino Cloud.`],
        skills: [`Programming skills in Python, C, C++...`,`Sensor operator skills...`,`Circuit design...`,`Computer skills...`,`Microprocessor and microcontroller skills.`],
    },
    {
        
        id: 21,
        age:"15-18",
        image: MakerSS,
        image2: MakerSS2,
        title: "Maker SS",
        ageGroupId: 4,
        categoryId: 7,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "",
        price: "2000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "",
        time: "",
        teacher: "",
        CODE: "MSS-WS",
        shortDesc: "",
        longDesc: "",
        purpose: "",
        skills: [""],
    },
    {
        
        id: 22,
        age:"15-18",
        image: printing,
        image2: printing2,
        title: "3D Printing",
        ageGroupId: 4,
        categoryId: 7,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "120 min.",
        price: "3000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht, 15 Janar",
        time: "Shtator-Dhjetor, Shkurt-Maj",
        teacher: "",
        CODE: "3D-WS",
        shortDesc: "Ky kurs do të demonstrojë punën e printerit 3D, duke treguar se çfarë bëjnë njerëzit me të dhe do të shqyrtojë ekosistemin e printimit 3D.",
        longDesc: [`Printimi 3D i referohet procesit të prodhimit që formon ose krijon në mënyrë shtesë një objekt fizik nga një dizajn digjital. Ekzistojnë teknologji dhe materiale të ndryshme të printimit 3D, dhe të gjitha bazohen në të njëjtin parim që një model dixhital riformësohet në objektin e ngurtë tredimensional duke shtuar shtresë pas shtresës.`],
        purpose: [`Kursi i printimit 3D do të eksplorojë gjithashtu të ardhmen e tij dhe do të diskutojë se si do të revolucionarizojë botën. Pas përfundimit të këtij kursi, studentët do të kenë një kuptim solid të printimit 3D, potencialin e tij dhe aftësinë për të printuar dizajne 3D.`],
        skills: [`Kuptim të përgjithshëm për 3D printimin.`,`Njohje me procesin e printerit 3D të nivelit të konsumatorit.`,`Njihuni me studimet e rasteve të printimit 3D.`,`Njohje me materialet e përshtatshme për 3D printim për nevoja specifike të konsumatorit.`],
    },
    {
        
        id: 23,
        age:"15-18",
        image: WeeDoo1,
        image2: WeeDoo2,
        title: "HTML, CSS, JS",
        ageGroupId: 4,
        categoryId: 3,
        durationMonths: "",
        durationWeek: "",
        durationHour: "",
        price: "",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "",
        time: "",
        teacher: "",
        CODE: "",
        shortDesc: "",
        longDesc: "",
        purpose: "",
        skills: [""],
    },
    {
        
        id: 24,
        age:"15-18",
        image: WeeDoo1,
        image2: WeeDoo2,
        title: "Python",
        ageGroupId: 4,
        categoryId: 4,
        durationMonths: "",
        durationWeek: "",
        durationHour: "",
        price: "",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "",
        time: "",
        teacher: "",
        CODE: "",
        shortDesc: "",
        longDesc: "",
        purpose: "",
        skills: [""],
    },
    {
        
        id: 25,
        age:"15-18",
        image: Phonephysics,
        image2: Phonephysics2,
        title: "Phonephysics",
        ageGroupId: 4,
        categoryId: 7,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "120 min.",
        price: "3000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht, 15 Janar",
        time: "Shtator-Dhjetor, Shkurt-Maj",
        teacher: "",
        CODE: "3D-WS",
        shortDesc: "Your smartphone is a mobile lab. ",
        longDesc: [`Kursi bazohet në aplikacionin Phyphox që është krijaur në 2nd Institute of Physics të RWTH Aachen University.`],
        purpose: [`Njohje thelbësore dhe praktike e koncepteve bazë fizike.`],
        skills: [`Manipulim me pajisje të ndryshme dhe njohje me punën hulumtuese shkencore.`,`Përdorim të software-ve për përpunim të të dhënave.`,`Dizajnim të eksperimenteve reale me interes shoqëror.`],
    },
    {
        
        id: 26,
        age:"4-18",
        image: KurseShkollore,
        image2: KurseShkollore2,
        title: "Kurse Shkollore",
        ageGroupId: 1,
        categoryId: 5,
        durationMonths: "8",
        durationWeek: "32",
        durationHour: "2 x 60 min. ",
        price: "1500",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "CC-WS",
        shortDesc: "Kurset Shkollore përfshinë lëndët nga Matematika, Fizika, Kimi, Biologjia dhe Informatika. ",
        longDesc: [`Kurset Shkollore përfshinë lëndët nga Matematika, Fizika, Kimi, Biologjia dhe Informatika. Këto kurse organizohen në mënyrë individuale ose në grupe të vogla dhe mbahen në ambiente të pajisura me gjitha mjetet teknologjike dhe software-ike që kanë për qëllim të të kuptuarit më të lehtë të njësive përkatëse.`],
        purpose: [`Qëllimi i kurseve është themelimi i njohurive të nxënësit në lëndët përkatëse dhe arritja e një suksesi më të lartë në procesin e vlersimit. E gjithë kjo do të bëhet me një qasje më unike nga stafi i trajnuar dhe me eksperiencë në lëmitë përkatëse`],
        skills: [`Rritje të performansës në shkollë në lëndën përkatëse ku ndiqet kursi.`,`Zgjerim të njohurive për problematike të cilat mësohen në shkollë.`,`Siguri në aplikimin e njohurive në situatat ku interdisiplinariteti është i nevojshëm.`],
    },
    {

        id: 27,
        age:"6-18",
        image: WeeDoo1,
        image2: WeeDoo2,
        title: "Kurse të Avancuara Shkollore",
        ageGroupId: 2,
        categoryId: 5,
        durationMonths: "8",
        durationWeek: "16",
        durationHour: "120 min.",
        price: "3000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht",
        time: "Shtator-Maj",
        teacher: "",
        CODE: "ASC-WS",
        shortDesc: "",
        longDesc: [`Kurse të Avancuara Shkollore janë të dizajnuara për përgatitjen e nxënësve për pjesëmarrje në gara të ndryshme në fusha të caktuara. Kurset do të mbahen me staf të dëshmuar, me më së shumti 4 nxënës për grup, të cilët do të kenë një dinamikë krejtësisht tjetër nga procesi normal i të të mësuarit.`],
        purpose: [`Kurse të Avancuara Shkollore kanë për qëllim përgatitjen e nxënësve për pjesëmarrje në gara të ndryshme në fusha të caktuara. Nga ky kurs supozohet të burojnë edhe nxënësit-alumni, të cilët në të ardhme do të bartin procese të rëndësishme në fushat e STEM-it. `],
        skills: [`Arritje e njohurive më të veçanta në fushën e STEM profileve.`],
    },
    {

        id: 28,
        age:"11-18",
        image: MakerMS,
        image2: MakerMS2,
        title: "Maker  MS",
        ageGroupId: 3,
        categoryId: 7,
        durationMonths: "4",
        durationWeek: "16",
        durationHour: "2 x 120 min.",
        price: "3000",
        extracost: "Janë të siguruara nga STEMLab.",
        registration: "15 Gusht, 15 Janar",
        time: "Shtator-Dhjetor, Shkurt-Maj",
        teacher: "",
        CODE: "MMS-WS",
        shortDesc: "",
        longDesc: "",
        purpose: "",
        skills: "",
    },
];

export default { data};