const state = {
    ideaLabShow: false,
}
const mutations = {
    CHANGE_BUTTON_STATE(state){
        state.ideaLabShow = !state.ideaLabShow;
    }
}
const actions = {
    changeButtonState({ commit }){
        commit('CHANGE_BUTTON_STATE');
    }
}
const getters = {
    buttonState: state => state.ideaLabShow,
}
const buttonModule = {
    state,
    mutations,
    actions,
    getters
}

export default buttonModule;