<template>
  <div class="pt-24 pb-10 dotbg2">
    <div class="bg-no-repeat bg-auto h-full bg-top courses-background">
      <div class="grid px-7 md:grid-flow-col">
        <div></div>
        <div
          class="
            border-StemPurple border-l-2
            pl-4
            grid grid-flow-row
            md:grid-flow-col
          "
        >
          <div class="md:w-60">
            <h1 class="text-StemBlue font-bold text-4xl pr-4 text-left">
              Kurset e STEMLab
            </h1>
          </div>
          <div
            class="
              md:w-96
              text-StemTextSecond
              font-normal
              text-sm text-left
              pt-3
              md:pt-0
            "
          >
            Programet e edukimit që ofron STEMLab janë programet më të
            dëshmuarat e të të gjitha kohrave dhe janë alternativë unike e
            edukimit që stimulojnë mendimin kritik dhe inovativ, dashurinë dhe
            përkushtimin, punë praktike dhe shkathtësinë.
          </div>
          <div class="pt-3 row-start-3">
            <button>
              <a class="grid grid-flow-col gap-6" href="/courses">
                <img src="./../../assets/Svg/ArrowVector.svg" alt="" />
                <p class="text-StemLightRed font-semibold">
                  Gjithë kurset
                </p>
              </a>
            </button>
          </div>
        </div>
      </div>
      <div>
        <div class="pt-32 md:px-28 grid gap-y-2 md:grid-cols-6 grid-flow-row">
          <!-- cards -->
          <div class="md:col-span-6">
            <Carousel ref="flickity" :options="carouselOptions">
              <div class="carousel-cell" v-for="shaban in cards" :key="shaban">
                <CoursesCards :data="shaban"> </CoursesCards>
              </div>
            </Carousel>
          </div>

          <!-- <CoursesCards v-for="i of formattedSrc in items" :key="i"></CoursesCards> -->
          <!-- <div class="md:col-start-6">
            <div
              class="
                grid grid-flow-col
                gap-3
                place-content-center
                h-full
                md:place-content-end
              "
            >
              <button @click="previous()">
                <div
                  class="
                    flex
                    rounded-xl
                    hover:shadow-2xl
                    transition
                    duration-500
                    ease-in-out
                    transform
                    hover:-translate-y-1 hover:scale-110
                  "
                >
                  <img src="./../../assets/Svg/arrowleftR.svg" alt="" />
                </div>
              </button>
              <button @click="next()">
                <div
                  class="
                    flex
                    rounded-xl
                    hover:shadow-2xl
                    transition
                    duration-200
                    ease-in-out
                    transform
                    hover:-translate-y-1 hover:scale-110
                  "
                >
                  <img src="./../../assets/Svg/arrowrightR.svg" alt="" />
                </div>
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="float-left pl-48 pt-5">
                 <img src="./../../assets/Svg/Shapets.svg" alt="">
            </div> -->
    </div>
    <div class="flex flex-col"></div>
  </div>
</template>

<script>
import Carousel from "./../../components/base/carousel.vue";
import CoursesCards from "./../../components/base/coursescards.vue";
import courses from "@/data/courses";

export default {
  components: {
    Carousel,
    CoursesCards,
  },

  data() {
    return {
      cards: courses.data,
      carouselOptions: {
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        // cellAlign: 'right',
        // contain: true

        // any options from Flickity can be used
      },
    };
  },

  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1025px) {
  .courses-background {
    background-image: url("./../../assets/images/Our_Courses.png");
  }
  .coursesbg {
    background-image: url("./../../assets/images/Ourcurses2.png");
    height: 163px;
    width: 232px;
  }
  .dotbg2 {
    background-image: url("./../../assets/Svg/dotcluster2.svg");
    background-repeat: no-repeat;
    background-position-x: 10%;
    background-position-y: 30%;
  }
  .carousel-cell {
    display: block;
    padding-top: 50px;
    padding-inline: 20px;
    margin-right: 6%;
    width: max-content;
    height: 550px;
  }
}
@media only screen and (max-width: 1024px) {
  .courses-background {
    background-image: url("./../../assets/images/Our_Courses.png");
  }
  .dotbg2 {
    background-image: url("./../../assets/Svg/dotcluster2.svg");
    background-repeat: no-repeat;
    background-position-x: 5%;
    background-position-y: 40%;
  }
}
@media only screen and (max-width: 640px) {
   .carousel-cell {
    display: block;
    padding-top: 50px;
    padding-inline: 20px;
    margin-right: 6%;
    width: max-content;
    height: 550px;
  }
}
</style>
