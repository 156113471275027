<template>
  <div class="">
    <div class="aboutusbg-one">
      <div class="ourcoursesbg_svg">
        <div class="childrenbg">
          <div class="pt-5">
            <div class="px-5 md:pl-28">
              <div class="border-StemPurple border-l-2 pl-4">
                <h1 class="text-StemBlue font-bold text-4xl pr-4 text-left">
                  Për ne
                </h1>
              </div>
              <div class="">
                <p
                  class="
                    text-StemTextSecond
                    font-normal
                    text-sm
                    pr-4
                    leading-loose
                    text-left
                    pt-10
                    md:w-80
                  "
                >
                  STEMLab është e fokusuar në edukimin e gjeneratave të reja dhe në krijimin e profesionistëve në STEM (Science, Technology, Engineering, Math) edukim, të cilët do të jenë ndërveprues me ambientin ku jetojnë, me kreativitet të lartë në punën e tyre dhe të gatshëm për edukim që zgjatë gjatë gjithë jetës. 
                </p>
              
                <div class="float-left pt-20">
                  <router-link to="/about">
                  <button
                    class="
                      rounded-lg
                      bg-StemYellow
                      hover:bg-transparent hover:text-StemYellow
                      text-white
                      border
                      hover:border-StemYellow
                      text-xl
                      font-normal
                      shadow-2xl
                      px-10
                      py-4
                    "
                  >
                    Më shumë
                  </button>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="pb-20 md:pb-52"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>

@media only screen and (min-width: 1025px) {
.aboutusbg-one {
  background-image: url("./../../assets/Svg/aboutusbgsvg.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  /* z-index: 1; */
}
.ourcoursesbg_svg {
  background-image: url("./../../assets/Svg/AboutUs.svg");
  background-repeat: no-repeat;
  background-position-x: 20%;
  background-position-y: 10%;
  z-index: -1;
}
.childrenbg {
  background-image: url("./../../assets/images/image22.png");
  background-repeat: no-repeat;
  background-position-x: 70%;
  background-position-y: 100%;
  /* z-index: 2; */
}
}
@media only screen and (max-width: 1024px) {
  .ourcoursesbg_svg {
  background-image: url("./../../assets/Svg/AboutUs.svg");
  background-repeat: no-repeat;
  background-position-x: 20%;
  background-position-y: top;
  z-index: -1;
}


}
@media only screen and (max-width: 640px) {

}

</style>