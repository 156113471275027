<template>
<div class="bg-white ">
  <NavBarNew></NavBarNew>
  <Hero></Hero>
  <WeOffer></WeOffer>
  <OurCurses></OurCurses>
  <OurProducts></OurProducts>
  <AboutUs></AboutUs>
  <Partners></Partners>
  <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import Hero from "@/components/HomeScreen/hero.vue";
import WeOffer from "@/components/HomeScreen/weoffer.vue";
import OurCurses from "@/components/HomeScreen/ourcurses.vue";
import AboutUs from "@/components/HomeScreen/AboutUs.vue";
// import OurProducts from "@/components/HomeScreen/OurProducts.vue";
import Partners from "@/components/HomeScreen/Partners.vue";
import Footer from "@/components/HomeScreen/Footer.vue";
//import Header from '@/components/HomeScreen/header.vue'

export default {
  name: "Home",
  components: {
    NavBarNew,
    Hero,
    WeOffer,
    OurCurses,
    AboutUs,
    // OurProducts,
    Partners,
    Footer,
  },
};
</script>
