<template>
  <div class="grid grid-flow-row shadow-2xl w-72             transition
            duration-500
            ease-in-out
            transform
            hover:-translate-y-1 hover:scale-110 hover:shadow-xl">
    <div class="">
      <!-- <img class="" src="./../../assets/images/cardimg1.png" alt=""> -->
      <img :src="data.image" alt="" />
    </div>
    <div class="grid justify-items-start px-4">
      <div class="grid grid-flow-col pt-6 gap-20">
        <h1 class="text-xs font-bold text-CardGreen">Përshkrimi i kursit</h1>
        <div class="rounded-lg bg-CardBlue text-white border text-xs px-1">
          {{data.age}} vjec
        </div>
      </div>
      <div class="">
        <h1 class="text-StemBlue text-lg py-2">{{ data.title }}</h1>
      </div>
      <div class="w-60 h-14">
        <p class="text-xs text-StemTextSecond text-left ">
          {{ data.shortDesc }}
        
        </p>
      </div>
      <div class="grid place-self-center py-2">
        <p class="text-StemYellow text-sm font-bold">
          {{ data.price }} MKD në muaj
        </p>
      </div>
      <div class="grid justify-self-center pb-7">
        <button
          @click="$router.push({ path: `/courses_description/${data.id}` })"
          class="
            rounded-lg
            bg-StemYellow
            hover:bg-transparent hover:text-StemYellow
            text-white
            border
            hover:border-StemYellow
            text-base
            font-normal
            w-64
            h-14
          "
        >
          Më shumë
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>
<style>
.coursesbg1 {
  background-image: url("./../../assets/images/cardimg1.png");
  background-repeat: no-repeat;
  height: 203px;
  width: 288px;
}
</style>