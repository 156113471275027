import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import cartModule from './modules/cart'
import buttonModule from './modules/button'


export default createStore({
  plugins: [createPersistedState()],
  modules: {
    cartModule,
    buttonModule,
  }
})
